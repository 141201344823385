<template lang="pug">
  v-container
    //- <!-- <tooltip-btn
    //-   btn-class="mr-2"
    //-   :outlined="$route.name !== 'stocks.create'"
    //-   rounded
    //-   tooltip="New Stock"
    //-   @click="routePush({ name: 'stocks.create' })"
    //- >
    //-   <v-icon>mdi-clipboard
    //-   <v-icon>mdi-plus
    //-  -->
    base-navigation-tabs(
      :items="links"
    )
    router-view
</template>
<script>
import searchDelay from '@/libs/searchDelay.extra'

const pushDelay = searchDelay(100)

export default {
  name: 'Stocks',
  data: () => ({
    links: [
      { label: 'Stocks', path: { name: 'stocks.manage' } },
      { label: 'Categories', path: { name: 'stocks.categories' } },
      { label: 'Feeding Programs', path: { name: 'stocks.feeding-programs' } },
      { label: 'Feed Details', path: '/stocks/feed-details' },
      { label: 'Units', path: '/stocks/units' },
    ],
  }),
}
</script>
